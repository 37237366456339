'use client';

import { useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import PasswordMatcher from '~/app/[locale]/auth/_components/PasswordMatcher/PasswordMatcher';
import Modal from '~/components/customComponents/modal/Modal';
import { SEARCH_PARAM_SET_PASSWORD_MODAL } from '~/constants';
import {
  setItemToLocalStorage,
  updateHasSeenModal,
  updateSearchParams,
} from '~/utils/clientUtils';

const SetPasswordModal = () => {
  const searchParams = useSearchParams();
  const isOpen = Boolean(searchParams.get(SEARCH_PARAM_SET_PASSWORD_MODAL));
  const { data } = useSession();
  const user = data?.user;

  const closeModal = useCallback(() => {
    updateSearchParams('');
    setItemToLocalStorage('hasSeenModal', 'true');
    updateHasSeenModal('true');
  }, []);

  if (!isOpen) return null;

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} classname="md:pt-0 lg:pt-0">
      <PasswordMatcher
        email={user?.email ?? ''}
        mode="create"
        isFirstTimeUser={true}
        isSetPasswordModal={isOpen}
      />
    </Modal>
  );
};

export default SetPasswordModal;
