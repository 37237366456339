'use client';

import { memo } from 'react';
import Toast from '~/components/customComponents/toast/Toast';
import {
  CHANGES_SAVED,
  ERROR_HAS_HAPPENED,
  ITEM_CAN_NOT_MERGE,
  ITEM_DELIVERY_DATE_HAS_CHANGED,
  ITEM_HAS_BEEN_MERGED,
  ITEM_HAS_BEEN_REMOVED,
  ITEM_QUANTITY_HAS_CHANGED,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CREATE_SUCCESS,
  REQUEST_HAS_BEEN_SENT,
} from '~/constants/queryParams';
import { useI18n } from '~/locales/client';
import { ToastTypeEnum } from '~/types-and-enums/toastTypes';

/**
 * Display's Changes saved toast and/or please try again toast
 */
const DisplayToasts = memo(() => {
  const translate = useI18n();
  return (
    <>
      <Toast
        headerText={translate('general.changes-saved')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={CHANGES_SAVED}
        duration={2000}
      />

      <Toast
        headerText={translate('page.shop.quantity-has-changed')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={ITEM_QUANTITY_HAS_CHANGED}
        duration={2000}
      />
      <Toast
        headerText={translate('page.shop.delivery-date-has-changed')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={ITEM_DELIVERY_DATE_HAS_CHANGED}
        duration={2000}
      />
      <Toast
        headerText={translate('page.shop.item-removed-successfully')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={ITEM_HAS_BEEN_REMOVED}
        duration={2000}
      />
      <Toast
        headerText={translate('page.cart.item-has-been-merged')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={ITEM_HAS_BEEN_MERGED}
        duration={3000}
      />
      <Toast
        headerText={translate('page.quotes.request.view.finish.modal.title')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={REQUEST_HAS_BEEN_SENT}
        duration={3000}
      />
      <Toast
        headerText={translate('general.password-changed')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={PASSWORD_CHANGE_SUCCESS}
        duration={3000}
      />
      <Toast
        headerText={translate('general.password-created')}
        type={ToastTypeEnum.Success}
        toastOpenSearchParam={PASSWORD_CREATE_SUCCESS}
        duration={3000}
      />
      <Toast
        headerText={translate('page.cart.item-can-not-merge')}
        type={ToastTypeEnum.Error}
        toastOpenSearchParam={ITEM_CAN_NOT_MERGE}
        duration={5000}
      />
      <Toast
        type={ToastTypeEnum.Error}
        toastOpenSearchParam="errorText"
        duration={1500}
      />
      <Toast
        headerText={translate(
          'component.password-matcher.view.submission.failure.description'
        )}
        type={ToastTypeEnum.Error}
        toastOpenSearchParam={ERROR_HAS_HAPPENED}
        duration={3000}
      />
    </>
  );
});

DisplayToasts.displayName = 'DisplayToasts';

export default DisplayToasts;
