type SwitchProps = {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

/**
 * Switch component that allows toggling between two states.
 * @param checked - boolean to determine if the switch is checked
 * @param onChange - function to call when the switch is toggled
 * @param disabled - boolean to determine if the switch is disabled
 */
const Switch = ({ checked, onChange, disabled }: SwitchProps) => {
  return (
    <button
      onClick={onChange}
      disabled={disabled}
      className={`relative w-10 h-5 flex items-center rounded-full p-1 transition ${
        checked ? 'bg-red-600' : 'bg-gray-300'
      } ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
    >
      <div
        className={`w-4 h-4 bg-white rounded-full shadow-md transform transition ${
          checked ? 'translate-x-5' : 'translate-x-0'
        }`}
      ></div>
    </button>
  );
};

export default Switch;
