'use client';

import { Provider as JotaiProvider } from 'jotai';
import { SessionProvider } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { I18nProviderClient } from '~/locales/client';
import { LocaleEnum } from '~/locales/resources';
import '~/styles/index.css';

const CookieHandler = dynamic(() => import('./_components/CookieHandler'), {
  ssr: false,
});

const Tracking = dynamic(() => import('./_components/Tracking'), {
  ssr: false,
});

const AddUserIdToHotjarScript = dynamic(
  () => import('~/components/analytics/AddUserIdToHotjarScript'),
  { ssr: false }
);

type ProviderProps = {
  children: React.ReactNode;
  locale: LocaleEnum;
};

/**
 * Providers for the app
 * @param children - The children to render
 * @param locale - The locale to use
 */
const Providers = ({ children, locale }: ProviderProps) => {
  return (
    <SessionProvider>
      <I18nProviderClient locale={locale}>
        <JotaiProvider>
          <CookieHandler />
          {children}
          <Tracking locale={locale as LocaleEnum} />
        </JotaiProvider>
      </I18nProviderClient>
      <AddUserIdToHotjarScript />
    </SessionProvider>
  );
};

export default Providers;
