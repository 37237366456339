'use client';

import { MouseEvent } from 'react';
import Button from '~/components/customComponents/buttons/Button';
import Flex from '~/components/customComponents/flex/Flex';
import Modal from '~/components/customComponents/modal/Modal';
import Switch from '~/components/customComponents/switch/NewSwitch';
import Text from '~/components/customComponents/texts/Text';
import { useI18n } from '~/locales/client';
import {
  CookieSettingType,
  CookieTypeEnum,
} from '~/types-and-enums/cookieTypes';
import { getCookieSettings } from '~/utils/cookieUtils';

export type CookiePreferencesProps = {
  isModalOpen: boolean;
  onClose: () => void;
  handleCookieSettingClick: (setting: CookieSettingType) => void;
  handleConfirm: () => void;
  selectedCookies: CookieTypeEnum[];
  setSelectedCookies: (cookies: CookieTypeEnum[]) => void;
};

export default function CookiePreferences({
  isModalOpen,
  onClose,
  handleCookieSettingClick,
  handleConfirm,
  selectedCookies,
  setSelectedCookies,
}: CookiePreferencesProps) {
  const t = useI18n();

  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={onClose}
      size="sm"
      classname="w-full md:w-[60vw]"
    >
      <div className="p-6">
        <h2 className="text-lg font-semibold text-center w-full">
          {t('cookieBanner.modal.title')}
        </h2>
        <Flex className="flex-col p-4">
          <Text>{t('cookieBanner.modal.description')}</Text>
          <Text className="pt-4 text-center">
            <Button
              onClick={() => setSelectedCookies([CookieTypeEnum.Functional])}
              text={t('cookieBanner.modal.optOutExtras')}
              buttonClassName="bg-white hover:bg-gray-50"
              textClassName="text-gray-900"
            />
          </Text>
          {getCookieSettings().map((setting) => (
            <Flex key={setting.type} className="flex-col mt-8">
              <button
                className="flex items-center gap-2 mb-2"
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  handleCookieSettingClick(setting);
                }}
              >
                <Text className="font-bold text-left">{t(setting.title)}</Text>
                <Switch
                  checked={selectedCookies.includes(setting.type)}
                  disabled={setting.required}
                  onChange={() => handleCookieSettingClick(setting)}
                />
              </button>
              <Text>{t(setting.description)}</Text>
            </Flex>
          ))}
        </Flex>
        <Flex className="gap-2 justify-center flex-col md:flex-row p-4">
          <Button
            onClick={handleConfirm}
            text={t('cookieBanner.modal.button.confirm')}
            buttonClassName="hover:bg-red-700"
          />
          <Button
            onClick={onClose}
            text={t('cookieBanner.modal.button.cancel')}
            buttonClassName="bg-white hover:bg-gray-50"
            textClassName="text-gray-900"
          />
        </Flex>
      </div>
    </Modal>
  );
}
